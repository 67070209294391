import { SectionLayoutType } from '@/shared/models/Section';
import { ISectionComponentProps } from '../utils';
import SingleDocument from './Layout/SingleDocument';
import MultipleDocuments from './Layout/MultipleDocuments'

export default function DocumentsContainer(props: ISectionComponentProps) {
    const { section } = props
    if(section.layout === SectionLayoutType.DEFAULT) {
       return (
           <SingleDocument {...props}/>
       )
    }
    return <MultipleDocuments {...props}/>
}