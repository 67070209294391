import Box from '@material-ui/core/Box';
import { GridJustification } from '@material-ui/core/Grid/Grid';
import TextBaseLayout from 'components/Sections/Text/TextBaseLayout';
import Tag from '@/shared/components/Tag/Tag';
import { ISectionComponentProps } from 'components/Sections/utils';
import { useIntl } from 'react-intl';
import { sectionMessages } from 'components/Sections/messages';
import { downloadUrl } from '@/shared/utils/helpers';

const iconPath = `${process.env.NEXT_PUBLIC_CDN}/${process.env.NEXT_PUBLIC_ASSETS_IMAGES}`
function SingleDocument(props: ISectionComponentProps) {
    const { section } = props
    const intl = useIntl()
    const justifyContent = {
        'left': 'flex-start',
        'right': 'flex-end',
        'center': 'center'
    }[section?.customize?.format?.alignment] as GridJustification
    return (
        <Box>
            <Box display='flex' justifyContent={justifyContent}>
                <Tag size='large' imgSrc={`${iconPath}/website-builder/16px/Document.svg`} />
            </Box>
            <TextBaseLayout
                title={section.name}
                button
                openInNewWindow={true}
                paragraph={section?.content?.paragraph ?? ''}
                buttonText={intl.formatMessage(sectionMessages['document.download'])}
                alignment={section?.customize?.format?.alignment}
                onClickBtn={() => {
                    downloadUrl(section?.content?.document?.url, true)
                }}
            />
        </Box>
    )
}

export default SingleDocument